@import "../../styles/mixins/typography.scss";
@import "../../styles/mixins/media.scss";

.cookie {
  position: fixed;
  bottom: 0;
  z-index: 102;
  display: flex;
  padding: var(--sp-4) var(--sp-5) var(--sp-5) var(--sp-5);
  background-color: var(--palette-white);
  box-shadow: 0 0 8px 6px rgb(12 19 26 / 19%);
  border-radius: 10px;
  width: 100%;

  @include portrait-mobile {
    bottom: 20px;
    right: 20px;
    max-width: 350px;
  }

  &__wrapper {
    width: 0;
    flex-grow: 1;
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
  }

  &__close {
    border-radius: 5px;
    width: 22px;
    height: 22px;
    position: absolute;
    top: 6px;
    right: 6px;
  }

  &__text {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    @include body;
    padding-bottom: var(--sp-3);
  }

  a {
    color: var(--palette-primary)
  }

  &__agree {
    margin-left: auto;
    margin-right: 0;
    padding-right: var(--sp-4);
  }
}

.slide-right {
  &-enter {
    opacity: 0;
    animation-delay: 30s;
    transform: translateX(100%);
  }
  &-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: 0.5s ease-in-out;
  }

  &-exit {
    opacity: 1;
    transform: translateX(0);
  }
  &-exit-active {
    opacity: 0;
    transform: translateX(100%);
    transition: 0.2s ease-in-out;
  }
}
